
import { Vue } from 'vue-class-component';
import fly from "flyio";

export default class Home extends Vue {
  mounted() {
    this.parseURL();
  }

  private parseURL() {
    let token = this.$route.query["token"];
    if (token) {
      console.log("parseURL token : ", token);
      this.apiResource(String(token));
    } else {
      this.onError();
    }
  }

  private apiResource(token:string): void {
    // let loader = this.$loading.show();
    let url = (process.env["ApiUrls"] as any)["lobbyApi"] + "/api/v1/game/resource";
    fly.post(url, {
        "access_token": token
    })
    .then((response) => {
      console.log("Api resource response : ", response);
      if(response.data.code === "0" ){
        let { theme } = response.data.result;
        console.log("api Resource theme : ", theme);
        if (theme) {
          this.onGame(String(theme));
        } else {
          this.onError();
        }
      } else {
        let message = String(response.data.message);
        console.log("Api resource message : ", message);
        window.alert(message);
        this.onError();
      }
    })
    .catch((error) =>{
      console.warn("Api resource error : ", error);
    });
  }

  private onGame(result:string): void {
    let api_parameter = "&lobbyApi="+(process.env["ApiUrls"] as any)["lobbyApi"]+"&loginApi="+(process.env["ApiUrls"] as any)["loginApi"];
    let url = result + "/cc_000/" + location.search;
    console.log("onGame url : ", url);
    location.replace(url);
  }

  private onLaunch(): void {
    let launch = "/launch" + location.search;
    this.$router.replace(launch);
  }

  private onError():void{
    let url = location.origin+"/brand/maintain.html";
    console.log("onError url : ", url);
    location.replace(url);
  }

}
